// // Do not update, dx extension are needed to be created before angular application
// import { DxExtensions } from '@ic/pwp-ui';

// import { enableProdMode, importProvidersFrom } from '@angular/core';
// import { bootstrapApplication } from '@angular/platform-browser';
// import { AppComponent } from '@ic/pwp-ui';
// import { AppModule } from './app/app.module';

// import { environment } from './environments/environment';
// if (environment.production) {
//   enableProdMode();
// }

// bootstrapApplication(AppComponent, {
//   providers: [
//     importProvidersFrom(AppModule)
//   ]
// })

import { DxExtensions } from '@ic/pwp-ui';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
