import { NgModule } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule, HammerGestureConfig, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LetModule, PushModule } from '@ngrx/component';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import {
  PwpUiModule,
  ENVIRONMENT,
  IMPORT_THEME_MODULE,
  CLIENT_SERVICES_WORKER,
  AppComponent,
  NotFoundComponent
} from '@ic/pwp-ui';
import { importThemeModuleFactory } from './import-theme-module.factory';
import { createClientServicesWorkerFactory } from './worker/client-services-worker.factory';
import { appRoutes } from './app-routes';

const routes: Routes = [
  ...appRoutes,
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HammerModule,
    HttpClientModule,
    LetModule,
    PushModule,
    PwpUiModule,
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
    environment.production ? [] : StoreDevtoolsModule.instrument({ maxAge: 50, autoPause: true })
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/ui/' },
    { provide: ENVIRONMENT, useValue: environment },
    { provide: HAMMER_GESTURE_CONFIG, useClass: HammerGestureConfig },
    { provide: IMPORT_THEME_MODULE, useValue: importThemeModuleFactory },
    { provide: CLIENT_SERVICES_WORKER, useValue: createClientServicesWorkerFactory }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
